import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Injectable()
export class MailHelper {
  constructor(private firestore: AngularFirestore) {
  }
  public genericTemplate(header: string, body: string): string {
    return '<!DOCTYPE html>\n' +
      '<html>\n' +
      '<head>\n' +
      '  <meta charset="UTF-8">\n' +
      '  <title>UDAL Komandası</title>\n' +
      '  <style type="text/css">\n' +
      '    /* Stil kodlarınızı buraya əlavə edə bilərsiniz */\n' +
      '    body {\n' +
      '      margin: 0;\n' +
      '      padding: 0;\n' +
      '      background-color: #ffffff;\n' +
      '    }\n' +
      '  </style>\n' +
      '</head>\n' +
      '<body>\n' +
      '  <table width="100%" cellpadding="0" cellspacing="0" border="0">\n' +
      '    <tr>\n' +
      '      <td align="center">\n' +
      '        <!-- Başlıq -->\n' +
      '        <table width="600" cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse;">\n' +
      '          <tr>\n' +
      '            <td style="padding: 20px; background-color: #f7f7f7; text-align: center;">\n' +
      '              <h1>UDAL MMC</h1>\n' +
      '            </td>\n' +
      '          </tr>\n' +
      '          <!-- Məzmun -->\n' +
      '          <tr>\n' +
      '            <td style="padding: 20px;">\n' +
      '              <p>' + header + '</p>\n' +
      '              <p>' + body + '</p>\n' +
      '            </td>\n' +
      '          </tr>\n' +
      '          <!-- Altlıq -->\n' +
      '          <tr>\n' +
      '            <td style="padding: 20px; background-color: #f7f7f7; text-align: center;">\n' +
      '              <p>&copy; 2024 UDAL MMC. Bütün hüquqlar qorunur.</p>\n' +
      '            </td>\n' +
      '          </tr>\n' +
      '        </table>\n' +
      '      </td>\n' +
      '    </tr>\n' +
      '  </table>\n' +
      '</body>\n' +
      '</html>'
  }
  public sendMail(to: string, toName: string, subject: string, body: string, template = 'generic'): Promise<any> {
    return this.firestore.collection('emails').add({
      to: [
        {
          email: to,
          name: toName
        }
      ],
      from: {
        email: 'MS_5WymGl@action.udal.az',
        name: 'UDAL Komandası'
      },
      subject: 'Test Email',
      html: template === 'generic' ? this.genericTemplate(subject, body) : ''
    });
  };
}
