import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'getal-main-searchbar',
  templateUrl: './main-searchbar.component.html',
  styleUrls: ['./main-searchbar.component.scss']
})
export class MainSearchbarComponent {
  @Input() height: string = 'auto';
  @Input() marginBottom: string = 'initial';
  @Input() searchbar: boolean = false;
  @Output() searchValue = new EventEmitter<string>();

  onSearch(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchValue.emit(inputValue);
  }
}
